
.scheduled-sale-item {
  .remove-button {
    visibility: hidden;
  }

  &:hover {
    .remove-button {
      visibility: visible;
    }
  }
}

.glyphicon-refresh-animate {
    -animation: spin 1.2s infinite linear;
    -webkit-animation: spin2 1.2s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}


//For closing buttons on tab panes
.tab-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

// Ad-libs style selector to fill in text in the middle of a phrase
.adlib-select {
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
  text-decoration-style: dashed;

  &:hover {
    -webkit-text-decoration-style: dashed;
    text-decoration-style: dashed;
  }
}

.adlib-inline-field {
  &.rbt .rbt-input-hint-container {
    display: inline-block !important; // Hack around the style so that the box doesn't wrap when adding selected options
  }
}